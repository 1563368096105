// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as farFaBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes as farFaTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

import { faMapMarkerAlt as fasFaMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons/faMapMarkerAlt";
import { faPhone as fasFaPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faTimes as fasFaTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";

import { faInstagram as fabFaInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faFacebookF as fabFaFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";

/**
 * Add regular icons
 */
library.add(farFaBars, farFaTimes);

/**
 * Add solid icons
 */
library.add(fasFaMapMarkerAlt, fasFaPhone, fasFaTimes);

/**
 * Add brands icons
 */
library.add(fabFaInstagram, fabFaFacebookF);


/**
 * Watch the DOM to insert icons
 */
dom.watch();
